import React from 'react'
import { Link } from 'gatsby'
import * as styles from './../styles/Card.module.scss'
import * as globalStyles from './../styles/Global.module.scss'
import { formatDate } from '../utils/format'
interface ArticleProps {
  article: Article
}

const Card: React.FC<ArticleProps> = ({ article }) => {
  const tags = article.articleTypes
  const displayDate = formatDate(article.articleDate)
  return (
    <Link to={`/news/${article.id}`} className="uk-link-reset">
      <div className={styles.cardWrapper}>
        <div className={`uk-card-body`}>
          <div className={styles.cardTitle}>{article.title}</div>
          <div className={`${globalStyles.row} ${styles.subRow}`}>
            <div className={styles.cardDate}>{displayDate}</div>
            <div className={` ${styles.cardTag}`}>
              {tags.map((tag, index) => (
                <div key={index} className={globalStyles.tagItem}>
                  {tag.typeName}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.cardContent}>{article.abstract}</div>
        </div>
      </div>
    </Link>
  )
}

export default Card
