import React from 'react'
import Card from './Card'

interface ArticlesProps {
  articles: Article[]
}

const Articles: React.FC<ArticlesProps> = ({ articles }) => {
  return (
    <div>
      <div className="uk-child-width-1-2" data-uk-grid>
        <div>
          {articles.map(article => {
            return <Card article={article} key={`article__${article.id}`} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Articles
