import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import { graphql } from 'gatsby'
// import PropTypes from 'prop-types'

import ArticlesComponent from '../components/Articles'
import * as globalStyles from './../styles/Global.module.scss'
import './../styles/ArticleList.css'
import ReactPaginate from 'react-paginate'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'
import { isFrontend } from '../utils/checkSide'

export interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
  allStrapiArticle: {
    nodes: Article[]
    pageInfo: {
      perPage: number
      itemCount: number
      pageCount: number
      totalCount: number
      hasPreviousPage: boolean
      hasNextPage: boolean
      currentPage: number
    }
  }
}

const infoCategories = [
  { name: '移民新闻', shortName: '新闻', link: '/news' },
  { name: '移民百科', shortName: '百科', link: '/pedia' },
  { name: '相关视频', shortName: '视频', link: '/video' }
]

export const InfoCategoriesNav = () => {
  return (
    <>
      <div className={globalStyles.desktopPageContentTitleRow}>
        {infoCategories.map((category) => {
          const isCurrent = isFrontend && window.location.pathname === category.link
          return (
            <Link
              key={`${category.name}${category.shortName}`}
              className={`${globalStyles.pageContentTitle} ${isCurrent && `${globalStyles.pageContentTitleOnActive}`}`}
              to={`${category.link}`}
            >
              {category.name}
            </Link>
          )
        })}
      </div>
      <div className={globalStyles.mobilePageContentTitleRow}>
        {infoCategories.map((category) => {
          const isCurrent = isFrontend && window.location.pathname === category.link
          return (
            <Link
              key={`${category.name}${category.shortName}`}
              className={`${globalStyles.pageContentTitle} ${isCurrent && `${globalStyles.pageContentTitleOnActive}`}`}
              to={`${category.link}`}
            >
              {category.shortName}
            </Link>
          )
        })}
      </div>
    </>
  )
}

const ArticleList = ({ data }: { data: StaticQueryProps; pageContext: PageContext }) => {
  const pageData = data.allStrapiArticle.pageInfo
  const handlePageClick = ({ selected }: { selected: number }) => {
    if (selected === 0 && isFrontend) navigate('/news')
    else if (isFrontend) navigate(`/news/${selected + 1}`)
  }
  return (
    <LayoutRoot>
      <>
        <InfoCategoriesNav />
        <ArticlesComponent articles={data.allStrapiArticle.nodes} />
        {pageData.pageCount > 1 && (
          <ReactPaginate
            className={`pagination ${globalStyles.pagnationOutLine}`}
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageData.perPage}
            marginPagesDisplayed={1}
            pageCount={pageData.pageCount}
            previousLabel="<"
            forcePage={pageData.currentPage - 1}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        )}
      </>
    </LayoutRoot>
  )
}

export const query = graphql`
  query ArticleListQuery($skip: Int!, $limit: Int!) {
    allStrapiArticle(skip: $skip, limit: $limit, sort: { fields: articleDate, order: DESC }) {
      pageInfo {
        perPage
        itemCount
        pageCount
        totalCount
        hasPreviousPage
        hasNextPage
        currentPage
      }
      nodes {
        id
        title
        abstract
        articleDate
        articleTypes {
          typeName
        }
        content {
          data {
            id
            content
          }
        }
      }
    }
  }
`

export default ArticleList
